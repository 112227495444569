import './App.css'
import StartComponent from './components/start'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AboutComponent } from './components/projects/about'
import { DonateComponent } from './components/DonateComponent'
import { ProjectComponent } from './components/project'
import { TakepartComponent } from './components/Takepart'
import { ContactComponent } from './components/ContactComponent'
import { ImpressumComponent } from './components/Impressum'
import { ProjectKiwokComponent } from './components/kiwokProject'
import { ProjectAhyoneComponent } from './components/ahyoneProject'

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<StartComponent />} />
                    <Route path="/WAKEUPSCHOOL" element={<ProjectComponent />} />
                    <Route path="/KIWOKOHOSPITAL" element={<ProjectKiwokComponent />} />
                    <Route path="/AHYONE" element={<ProjectAhyoneComponent />} />
                    <Route path="/ÜBER UNS" element={<AboutComponent />} />
                    <Route path="/SPENDEN" element={<DonateComponent />} />
                    <Route path="/MITMACHEN" element={<TakepartComponent />} />
                    <Route path="/KONTAKT" element={<ContactComponent />} />
                    <Route path="/IMPRESSUM" element={<ImpressumComponent />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App
