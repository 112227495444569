import '../styles/start.css'
import start from '../img/00_Logos/socialarchitecture LOGO+ de ohne.png'
import img1pas from '../img/01_Start/01_Arch-pas.jpg'
import img1akt from '../img/01_Start/01_Arch-akt.jpg'
import img2pas from '../img/01_Start/02_Arch-pas.jpg'
import img2akt from '../img/01_Start/02_Arch-akt.jpg'
import img3pas from '../img/01_Start/03_Arch-pas.jpg'
import img3akt from '../img/01_Start/03_Arch-akt.jpg'
import img4 from '../img/01_Start/04_Arch.jpg'
import img5 from '../img/01_Start/05_Arch.jpg'
import img6 from '../img/01_Start/06_Arch.jpg'
import img7 from '../img/01_Start/07_Arch.jpg'
import img8 from '../img/01_Start/08_Arch.jpg'
import img9 from '../img/01_Start/09_Arch.jpg'
import { TabsContainer } from './tabsContainer'
import { useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'

export default function StartComponent() {
    const widthByDevice = useCallback(() => {
        if (isMobile) {
            return 350
        }
        return 400
    }, [])
    const navigate = useNavigate()

    const [active, setActive] = useState<boolean>(false)
    const [activekiwoko, setActiveKiwoko] = useState<boolean>(false)
    const [activeahyone, setActiveAhyone] = useState<boolean>(false)

    const imgsWithLinks = [
        {
            src: img4,
            title: '',
        },
        {
            src: img5,
            title: '',
        },
        {
            src: img6,
            title: '',
        },
        {
            src: img7,
            title: '',
        },
        {
            src: img8,
            title: '',
        },
        {
            src: img9,
            title: '',
        },
    ]

    return (
        <TabsContainer>
            <hr />
            <div style={{ height: !isMobile ? '92px' : 'unset' }}>
                <div className="startimg">
                    <img
                        src={start}
                        width={isMobile ? '50%' : '30%'}
                        alt="start"
                    />
                </div>
            </div>
            <hr />
            <div className="startimgs">
                <img
                    className="imgelem"
                    onMouseEnter={() => setActive(true)}
                    onMouseLeave={() => setActive(false)}
                    key={img1pas}
                    title={'Wake up school'}
                    src={active ? img1akt : img1pas}
                    onClick={() => navigate('WAKEUPSCHOOL')}
                    alt="start"
                    height={widthByDevice()}
                />
                <img
                    className="imgelem"
                    onMouseEnter={() => setActiveKiwoko(true)}
                    onMouseLeave={() => setActiveKiwoko(false)}
                    key={img1pas}
                    title={'Kiwoko Hospital'}
                    src={activekiwoko ? img2akt : img2pas}
                    onClick={() => navigate('KIWOKOHOSPITAL')}
                    alt="start"
                    height={widthByDevice()}
                />
                <img
                    className="imgelem"
                    onMouseEnter={() => setActiveAhyone(true)}
                    onMouseLeave={() => setActiveAhyone(false)}
                    key={img1pas}
                    height={widthByDevice()}
                    title={'AH YONE OO'}
                    src={activeahyone ? img3akt : img3pas}
                    onClick={() => navigate('AHYONE')}
                    alt="start"
                />
                {imgsWithLinks.map(({ src, title }) => (
                    <img
                        className="imgelem"
                        key={src}
                        height={widthByDevice()}
                        title={title}
                        src={src}
                        alt="start"
                    />
                ))}
            </div>
            <hr />
        </TabsContainer>
    )
}
